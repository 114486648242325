.wishlist__wrapper {
    z-index: 20;
    overscroll-behavior-y: contain;
  }
  
  .wishlist__background {
    position: fixed;
    right: 2rem;
    height: 100vh;
    width: 100vw;
    background-color: var(--color-grey-secondary);
    opacity: 0.75;
  }
  
  .wishlist__content {
    position: absolute;
    right: 0;
    background-color: white;
  }
  
  .wishlist__nav {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 5rem;
    z-index: 30;
    background-color: white;
  }
  
  .wishlist__header {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .wishlist__counter {
    font-size: 1.25rem;
  }
  
  .wishlist__close {
    font-size: 2.5rem;
    cursor: pointer;
  }
  
  .wishlist__list {
    display: flex;
    flex-direction: column;
  }
  
  .wishlist__item {
    position: relative;
    display: flex;
    margin: 0 2rem;
    padding: 2rem 0;
    border-bottom: 0.1rem solid black;
  }
  
  .wishlist__item:last-child {
    border-bottom: none;
  }
  
  .wishlist__item--image {
    width: 8.5rem;
    height: 8.5rem;
  }
  
  .wishlist__content--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .wishlist__namePrice--wrapper {
    display: flex;
    font-weight: bold;
    font-size: 1.15rem;
  }
  
  .wishlist__item--name {
    margin: 0 1rem;
    max-width: 20rem;
  }
  
  .wishlist__button--wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .primary__button.wishlist {
    transform: scale(0.6);
    margin-left: -2rem;
  }
  
  .secondary__button.wishlist {
    transform: scale(0.6);
    margin-left: -2rem;
  }
  
  .wishlist__empty--list {
    font-size: 2rem;
    margin: 1rem;
  }
  
  @media screen and (max-width: 825px) {
    .wishlist__namePrice--wrapper {
      flex-direction: column;
    }
  
    .wishlist__item--price {
      margin-top: 1.5rem;
      margin-left: 1rem;
      margin-bottom: 2rem;
    }
  
    .primary__button.wishlist {
      margin-left: -9rem;
    }
  
    .mobile {
      display: block;
    }
  }
  