.offices_List {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 5px;
    padding-bottom: 12rem;
    background-color: #FAFAFA;
}
    
.offices_Card {
    position: relative;
    background-color: #fff;
    margin: 1rem;
    flex: 1 0 25rem;
    max-width: 35rem;
    max-height: auto;
    width: 30rem;
    transition: 0.2s;
    padding-bottom: 3rem;
}
    
.offices_Card:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}

.offices_big_icon{
    font-size: 5.2rem;
    color: #00C1FF;
    margin: 2rem 0;
}
.offices_Title{
    font-size: 2rem;
    padding: 1rem;
    font-weight: bold;
}
.offices_Text{
    font-size: 1.4rem;
}