.productList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5px;
  background-color: #f7f7f7;
  }
  
  .productCard {
    position: relative;
    background-color: #fff;
    margin: 0.2rem 1rem 1rem 1rem;
    flex: 1 0 25rem;
    max-width: 35rem;
    max-height: auto;
    width: 30rem;
    transition: 0.2s;
    padding-bottom: 1px;
  }
  
  .productCard:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
    border-radius: 2rem;
  }
  
  .productTitle{
    text-align: left;
    font-size: 2rem;
    padding: 1rem 1rem 1rem 2.5rem;
    font-weight: bold;
  }
  .productMore{
    text-align: left;
    font-size: 1.4rem;
    padding-left: 0.5rem;
    color: darkgray;
    cursor: pointer;
  }
  .productTitle2{
    text-align: center;
    font-size: 3rem;
    padding: 1rem 1rem 1rem 2.5rem;
  }

  .productImage {
    margin-top: 1rem;
    width: 27rem;
    height: 27rem;
  }
  .productImageSlide {
    height: 35rem;
    border-radius: 5px;
    margin-top: 1rem;
  }
  .productRating{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .product_circle_color{
    padding: 0.7rem;
    background-color: red;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border: darkgray solid 0.5px;
    border-radius: 0.7rem;
  }

  .product_circle_color_detail{
    padding: 1.2rem;
    background-color: red;
    margin-right: 0.6rem;
    margin-bottom: 0.5rem;
    border: darkgray solid 0.5px;
    border-radius: 1.2rem;
    cursor: pointer;
  }
 
  .productCard__cart {
    position: absolute;
    right: 0;
    margin: 1rem;
    font-size: 2rem;
    transition: 0.2s;
    cursor: pointer;
  }
  .productCard_float {
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    font-size: 2.4rem;
    transition: 0.2s;
    cursor: pointer;
  }

  .productCard_float_left {
    position: absolute;
    left: 0;
    top: 0;
    margin: 1rem;
    font-size: 2.8rem;
    transition: 0.2s;
    cursor: pointer;
  }
  .productCard_float_oferta,
  .productCard_float_renew,
  .productCard_float_new {
    position: absolute;
    top: 0rem;
    left: 2rem;
    margin-top: 2rem;
    padding: 0.2rem;
    font-size: 1.2rem;
    transition: 0.2s;
    background-color: #ed1c24;
    color: #fff;
    border-radius: 0.5rem;
  }
  .productCard_float_renew{
    background-color: #007ad1;
  }
  .productCard_float_oferta{
    background-color: orange;
  }

  .productCard_heart {
    position: absolute;
    right: 0;
    margin: 1rem;
    font-size: 2rem;
    transition: 0.2s;
    cursor: pointer;
  }
  
  .productCard__wishlist {
    position: absolute;
    right: 3rem;
    margin: 1rem;
    font-size: 2rem;
    transition: 0.2s;
    cursor: pointer;
  }
  
  .active {
    fill: var(--color-yellow-primary);
  }
  
  .productCard__newArrivals {
    position: absolute;
    font-size: 2.5rem;
    left: 0;
    margin: 1rem;
    fill: var(--color-green-primary);
  }
  
  .productCard__fastSelling {
    position: absolute;
    font-size: 2rem;
    left: 0;
    margin: 1rem;
    fill: var(--color-orange-primary);
  }
  
  .productCard__content {
    margin: 1rem 0 1rem 0rem;
    padding: 0 2rem;
  }
  
  .productName {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: left;
    color: #000;
  }

 

  
  .displayStack__1,
  .displayStack__2 {
    margin: 1.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .displayStack_box{
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .displayleft {
    margin: 1.5rem 0;
    text-align: left;
  }
  
  .productPrice {
    font-size: 1.5rem;
    font-weight: bold;
    color: red;
    text-decoration-line: line-through;
  }

  .productDescription {
    font-size: 14px;
    text-align: justify;
    margin-bottom: 4rem;
    line-height: 1.6;
  }
  
  .productSales,
  .productTime {
    font-size: 12px;
    margin-bottom: 3px;
    line-height: 1.6;
  }
  .product_desc {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: 700;
    line-height: 1.6;
    margin-left: 3px;
  }
  .product_text_error {
    color: red;
    text-align: center;
    padding-bottom: 5px;
  }
  
  .product_show_more {
    background-color: #000;
    color: #fff;
    padding: 1rem;
    text-align: center;
    margin: 1rem auto;
    width: 10rem;
    border-radius: 1.5rem;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .product_categorie_scroll{
    background-color: #fff;
    overflow: auto;
    white-space: nowrap;
    margin: 0 0.5rem;
  }
  .product_categorie_scroll::-webkit-scrollbar{
    display: none;
  }
  .product_categorie_block{
    display: inline-block;
    color: #000;
    text-align: center;
    padding: 0.5rem 1rem 0.5rem 1rem
  }
  .productCategorie_image{
    vertical-align: middle;
    width: 8rem;
    height: 8rem;
    cursor: pointer;
    margin: 0.5rem;
    border-radius: 2.5rem;
    transform: translateY(-0.1rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
  }
  .productCategorie_image:hover {
    transform: translateY(-0.6rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
  }
  .productCategorie_text{
    font-size: 1.4rem;
  }
  
  
  /*PRODUCT DETAIL*/

  .productDetailList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 2rem 1rem 2rem;
    background-color: #ffffff;
    }
  
  .productDetailCardImage {
    background-color: #fff;
    flex: 1;
    max-width: 45rem;
    max-height: auto;
    transition: 0.2s;
    margin-right: 2rem;
  }
  .productDetailCardInfo {
    position: relative;
    background-color: #fff;
    flex: 1 0 0rem;
    max-width: 45rem;
    max-height: auto;
    transition: 0.2s;
    margin-left: 2rem;
  }
  .productDetailList:hover {
    transform: translateY(-0.1rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
  }

  .productDetailCardInfo__content {
    margin: 2rem;
    width: 30em;
  }

  .displayBlocks {
    text-align: left;
    margin-left: 0rem;
  }

  .displayRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .displayView {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .productDetailImage {
    margin-top: 2rem;
    vertical-align: middle;
    width: 40rem;
    border-style: solid;
    border-width: 0.3px;
    border-color: silver;
  }
  .productDetailImageSmall {
    width: 100%;
    cursor: pointer;
  }
  .productDetailImageSmall:hover {
    transform: translateY(-0.1rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
  }
 
  .row1 {
    display: flex;
    flex-wrap: wrap;
    margin-left: 2.4rem;
  }
  .column1 {
    float: left;
    width: 16.66%;
    margin-right: 0.3rem;
  }

 
  

  .productDetailName {
    font-size: 3rem;
    padding-right: 10px;
    font-weight: 500;
  }
  .productSubTitle{
    font-size: 16px;
  }
  .productSubTitleGray{
    font-size: 14px;
    color: darkslategray;
  }
  .productSubTitleDesc{
    font-size: 16px;
    text-align: left;
    margin: 2.5rem 0 1rem 0;
    font-weight: 500;
  }
  .productDetailColor {
    font-size: 3rem;
    font-weight:100;
  }
  .productDetailPrice {
    font-size: 2rem;
    color: #000;
    padding-right: 3px;
  }
  .productDetailSize {
    font-size: 14px;
  }
  .boxBorder{
    border-style: solid;
    border-width: 0.5px;
    width: 45px;
    border-radius: 3rem;
    margin: 0 1rem 1rem 0;
  }
  .boxBorderSelected{
    border-style: solid;
    border-color: #00C1FF;
    border-width: 2px;
    width: 50px;
    border-radius: 3rem;
    margin: 0 1rem 1rem 0;
  }
  .productColor:hover{
    transform: translateY(-0.2rem);
    
  }
  .boxBorder:hover {
    transform: translateY(-0.2rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
  }
  .primary__button.detail {
    margin: 4rem 2rem 2rem 0rem;
    text-align: center;
  }
  .ProductDetailSelected{
    margin-top: 4rem;
  }
  .box_quantity--container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 3rem;
    width: 10rem;
    border: 0.1rem solid darkgray;
    border-radius: 2rem;
  }
  .product_corrida_box {
    margin: 2rem 0;
  }
  .product_corrida_btn {
    width: 12rem;
    padding: 0.8rem 0;
    margin-right: 2rem;
    border-bottom: solid 0.5px darkgray;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
    background: var(--color-blue-primary);
  }
  .product_corrida_btn:hover {
    transform: translateY(-0.1rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
    background-color: var(--color-yellow-primary);
  }
  .product_corrida_txt {
    text-align: center;
    font-size: 14px;
    color: #fff;
  }
  .product_corrida_edit {
    padding-top: 1rem;
    text-align: left;
    font-size: 14px;
    color: orange;
    cursor: pointer;
  }
  .product_corrida_txt2 {
    font-size: 12px;
  }
  .product_corrida_msj1 {
    margin-left: 2rem;
    font-size: 14px;
    color: green;
  }
  .product_corrida_msj2 {
    margin-left: 4rem;
    font-size: 12px;
    color: red;
  }
  .product_select{
    padding: 0.5rem 2rem;
    border: solid 0.5px darkgray;
    border-radius: 1rem;
    margin-right: 2rem;
    cursor: pointer;
  }
  .product_pad_bot {
    padding-bottom: 1rem;
  }
  .product_pad_left {
    padding: 0 3px;
  }

.product_input {
  border: none;
  border-bottom: 2px solid #D1D1D4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 20rem;
  transition: .2s;
  margin-top: 30px;
}
.product_btn {
  justify-content: center;
  width: 8rem;
  padding: 0.8rem 0;
  margin-top: 2rem;
  border-radius: 1rem;
  align-items: center;
  cursor: pointer;
  background: var(--color-blue-primary);
  color: #fff;
  text-align: center;
}

.productScanList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5px;
  background-color: #FAFAFA;
}

.productScanCard {
  position: relative;
  background-color: #fff;
  margin: 1rem 2rem 8rem 2rem;
  flex: 1 0 25rem;
  transition: 0.2s;
  padding: 4rem 0;
  justify-content: center;
  max-width: 45rem;
}

.scanInput{
  border: none;
	border-bottom: 1px solid #D1D1D4;
	background: none;
	padding: 10px;
  margin: 2rem 0;
	padding-left: 24px;
	font-weight: 700;
	width: 30rem;
	transition: .2s;
  margin-top: 30px;
}

.productAddCard {
  background-color: #fff;
  flex: 1;
  max-width: 35rem;
  max-height: auto;
  transition: 0.2s;
}
.productAddSpace {
  justify-content: space-between;
}
.productAddleft {
  text-align: left;
}

.productScanBtn{
  padding: 2rem 5rem 0rem 5rem;
}

.productBasketCard1 {
  position: relative;
  background-color: #fff;
  margin: 1rem 1rem 10rem 1rem;
  flex: 1 0 25rem;
  transition: 0.2s;
  padding: 1rem 0;
  justify-content: center;
  max-width: 45rem;
}

.productBasketCard2 {
  position: relative;
  background-color: #fff;
  margin: 1rem 1rem 10rem 1rem;
  flex: 1 0 25rem;
  transition: 0.2s;
  padding: 1rem 0;
  justify-content: center;
  max-width: 45rem;
}

.total_Final_text{
  font-size: 1.6rem;
  font-weight: 400;
}

@media screen and (max-width: 825px) {
  .productCard {
    width: 17rem;
    margin: 1rem 0.3rem;
    border-radius: 2rem;
  }
  .productImage {
    width: 16rem;
    height: 16rem;
  }
  .productName {
    font-size: 1.4rem;
  }
  
  .productDetailCardImage {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .productDetailImage {
    width: 35rem;
    border-width: 0px;
  }

  .row1 {
    margin-left: 2.6rem;
  }

  .productDetailCardInfo {
    margin-left: 0rem;
  }

  .productCard_float_oferta,
  .productCard_float_renew,
  .productCard_float_new {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
  .productImageSlide {
    height: 25rem;
    width: 25rem;
  }
  .productCategorie_image {
    width: 6rem;
    height: 6rem;
  }

  .product_corrida_msj1 {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}