.filter__wrapper {
    z-index: 20;
    overscroll-behavior-y: contain;
  }
  
.filter__background {
  position: fixed;
  left: 0rem;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-grey-secondary);
  opacity: 0.75;
}

.filter__content {
  position: absolute;
  left: 0;
  width: 30rem;
  background-color: white;
}

.filter__nav {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  z-index: 30;
  background-color: #f7f7f7;
  padding: 0 1rem;
}

.filter__header {
  font-size: 1.8rem;
  font-weight: 600;
  margin: auto;
}

.filter__close {
  font-size: 1.6rem;
  cursor: pointer;
}

.filter__list {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.filter_row {
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
}
.filter_cat {
font-size: 1.6rem;
font-weight: 400;
padding-left: 0.5rem;
cursor: pointer;
}
.filter_suCat {
font-size: 1.6rem;
color: gray;
cursor: pointer;
padding-left: 0.5rem;
}
.filter_row_sub {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  margin-left: 3rem;
  border-left: 0.1rem solid darkgray;
}

.filter_cat:hover,
.filter_suCat:hover{
  color: var(--color-yellow-primary);
}
