.confirmation_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 2rem 10rem 2rem;
    padding: 2rem;
    background-color: #FAFAFA;
    }
  
  .confirmation_card_address {
    background-color: #fff;
    flex: 1;
    max-width: 45rem;
    max-height: auto;
    transition: 0.2s;
    margin-right: 5rem;
    padding: 2rem;
    border-radius: 1rem;
  }

  .confirmation_card_payment {
    position: relative;
    background-color: #fff;
    flex: 1 0 5rem;
    max-width: 45rem;
    max-height: auto;
    transition: 0.2s;
    padding: 2rem;
    border-radius: 1rem;
  }

  .confirmation_subCard {
    padding: 1rem 2rem;
    margin: 2rem 0;
    border: 1px solid darkgray;
    border-radius: 1rem;
  }
  
  .confirmation_title {
    font-size: 2rem;
    text-align: left;
    font-weight: 400;
    border-style: solid;
    border-bottom-width: 0.5px;
    border-color: darkgray;
    padding-bottom: 2rem;
    width: 45rem;
  }
  .confirmation_subTitle{
    text-align: left;
    font-size: 1.6rem;
    font-weight:500;
  }
  .confirmation_subTitleCargo{
    text-align: left;
    font-size: 1.4rem;
    font-weight:500;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }
 .confirmation_select {
    margin-top: 2rem;
    justify-content: left;
    text-align: left;
    font-size: 1.6rem;
    font-weight: 100;
  }
  .confirmation_select_box1 {
    margin: 1rem 0 1rem 0;
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
    width: 100%;
  }
  .confirmation_select_box2 {
    margin: 0rem 0 0.8rem 0;
    padding: 1rem;
    text-align: left;
    cursor: pointer;
    background-color: #EAEAEA;
    width: 100%;
    border-radius: 1rem;
  }
  .confirmation_placeholder {
    text-align: left;
    font-size: 1.6rem;
    font-weight: 400;
    color: gray;
    padding: 1rem 0 1rem 0;
  }
  .confirmation_item {
    text-align: left;
    font-size: 1.4rem;
    font-weight: 400;
    padding-left: 2rem;
  }
  .confirmation_item_selected {
    text-align: left;
    font-size: 1.6rem;
    font-weight: 400;
  }
  .conf_add_box{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .conf_add_address{
    font-size: 14px;
    padding-left: 5px;
  }
  .confirmation_isvisible{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 2rem 2rem 2rem;
    cursor: pointer;
  }
  .confirmation_icon {
    padding: 0 2rem 0 2rem;
  }

  .confirmation_icon_big {
    font-size: 3rem;
    color: red;
    margin-right: 1rem;
  }

  .confirmation_icon:hover {
    transform: translateY(-0.1rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
  }

  .confirmation_icon_text {
    text-align: center;
  }
  .headerNameCenter{
    color: #000;
    flex: 1;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
  }
  .confirm_input{
    border: none;
    border-bottom: 1px solid darkgray;
    background: none;
    padding: 1rem;
    font-weight: 200;
    width: 25rem;
    transition: .2s;
    margin: 3rem 0 1rem 0;
  }

  .confirmation_card_confirm_up {
    position: relative;
    background-color: #00a650;
    flex: 1;
    height: 50%;
    width: 100%;
    min-height: 50vh;
    transition: 0.2s;
  }
  .confirmation_card_confirm_down {
    position: relative;
    background-color: #fff;
    flex: 1;
    height: 50%;
    width: 100%;
    min-height: 50vh;
    transition: 0.2s;
  }
  .failure_color {
    background-color: firebrick;
  }
  .pending_color {
    background-color: orange;
  }
  .confirmation_confirm_box {
    padding-top: 15rem;
  }
  .confirmation_confirm_text {
    font-size: 2.4rem;
    color: #fff;
    padding-top: 1rem;
  }
  .comfirmation_alert_box{
    padding: 1rem 0;
    background-color: #E3F8FF;
  }
  .comfirmation_alert_title{
    font-size: 1.6rem;
    font-weight: 400;
  }
  .comfirmation_alert_text{
    font-size: 1.4rem;
  }

  .confirmation_row{
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 825px) {
    .confirmation_list{
      background-color: #F0F0F0;
      margin: 0rem;
      padding: 1rem;
    }
    .confirmation_card_address {
      max-width: 35rem;
      margin-top: 2rem;
      margin-right: 0rem;
      padding: 1rem;
    }
    .confirmation_card_payment {
      max-width: 35rem;
      margin-top: 5rem;
      padding: 1rem;
    }
    .confirmation_title {
      width: 35rem;
      font-weight: 500;
    }
  }

  @media print {
    .no-print {
      display: none;
    }
    @page {
      margin-top: 0;
      margin-bottom: 0;
    }
    body  {
      padding-top: 72px;
      padding-bottom: 72px ;
    }
  }