.cart__wrapper {
    z-index: 20;
    overscroll-behavior-y: contain;
  }
  
  .cart__background {
    position: fixed;
    right: 2rem;
    height: 100vh;
    width: 100vw;
    background-color: var(--color-grey-secondary);
    opacity: 0.75;
  }
  
  .cart__content {
    position: absolute;
    right: 0;
    background-color: white;
  }

  .cart_delete_all{
    text-align: right;
    color: red;
    padding-right: 0rem;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 2rem;
  }
  
  .cart__nav {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 5rem;
    z-index: 30;
    background-color: white;
  }
  
  .cart__header {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .cart__counter {
    font-size: 1.25rem;
  }
  
  .cart__close {
    font-size: 2.5rem;
    cursor: pointer;
  }
  
  .cart__list {
    display: flex;
    flex-direction: column;
  }
  
  .cart__item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 2rem;
    padding: 1rem 0;
    background-color: #fff;
    border-bottom: 0.1rem solid darkgrey;
  }
  
  .cart__item--image {
    width: 8.5rem;
    height: 8.5rem;
  }
  
  .cart__content--wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .cart__namePrice--wrapper {
    text-align: left;
    font-size: 1.15rem;
    width: 15rem;
    margin-left: 1rem;
  }
  
  .cart__item--name {
    font-size: 12px;
    margin-left: 0rem;
    font-weight: bold;
  }
  .cart__item--discount {
    text-decoration-line: line-through;
    color: red;
  }
  .cart__item--price {
    margin-left: 0rem;
    font-size: 12px
  }
  .cart__item--price2 {
    margin-left: 1rem;
    font-size: 12px;
  }
  .cart__item--price-cursor {
    cursor: pointer;
  }
  .cart_disc_tag {
    font-size: 10px;
    color: gray;
    padding-bottom: 3px;
  }
  
  .cart__button--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  
  .cart__quantity--container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 2rem;
    height: 2rem;
    width: 6.5rem;
    border: 0.1rem solid black;
  }
  
  .cart__add--item,
  .cart__remove--item {
    cursor: pointer;
  }
  
  .cart__item--quantity {
    font-size: 1.25rem;
    text-decoration-line: underline;
    cursor: pointer;
    margin: 0 1rem;
  }
  
  .secondary__button.cart {
    transform: scale(0.5);
  }

  .totalBox{
    padding: 10px;
  }
  .total__wrapper {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .subTotal__wrapper {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    font-size: 1.25rem;
  }

  .subTotal_upperLine {
    border-top: 1px solid darkgray;
    padding-top: 0.5rem;
  }

  .total_upperLine {
    border-top: 1px solid #000;
    padding-top: 0.5rem;
  }
  
  .primary__button.cart {
    margin: 0 2rem 2rem 2rem;
    text-align: center;
  }
  
  .cart__empty--list {
    font-size: 2rem;
    margin: 1rem;
  }
  
  @media screen and (max-width: 825px) {
    .cart__namePrice--wrapper {
      flex-direction: column;
      width: 10rem;
    }
    .cart__quantity--container {
      margin-left: -6rem;
    }
    .cart__item {
      margin: 0 1rem;
    }
    .cart__item--image {
      width: 7rem;
      height: 7rem;
    }
  }
  
  .cart_Row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .cart_displayRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .cart_displayRow_center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: right;
    padding-right: 4rem;
    padding-bottom: 1rem;
  }
  .cart_align {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .cart_float_button {
    align-items: center;
    display: block;
    padding: 1rem;
    border-radius: 1.5rem;
    background-color: #000;
    margin-right: 1rem;
    cursor: pointer;
  }

  .cart_float_button_un{
    align-items: center;
    display: block;
    padding: 1rem;
    border-radius: 1.5rem;
    background-color: #D0D0D0;
    margin-right: 1rem;
    cursor: pointer;
  }
  .cart_float_text {
    justify-content: center;
    color: white;
    font-size: 0.9rem;
  }

  .cart_input{
    border: none;
    border-bottom: 1px solid #D1D1D4;
    background: none;
    padding: 5px;
    font-weight: 300;
    text-align: center;
    width: 20%;
    transition: .2s;
    margin:3rem 2rem 2rem 0;
  }
  .cart_trash{
    font-size: 1.8rem;
    margin-left: 3rem;
    color: red;
  }

  .cart_progress {
    width: 28rem;
    height: 1.5rem;
    border-radius: 13px;
    background: crimson;
    color: lightblue;
    margin-top: 2rem;
  }

  .cart_progress {
    border: none;
    background: grey;
   }
   
   .cart_progress {
    color: lightblue;
   }
   
   .cart_progress::-webkit-progress-value {
    background: #E0B0FF;
   }
   


  .cart_progress_text {
    font-size: 12px;
    margin: 0 1rem;
  }