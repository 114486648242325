:root {
    --color-grey-primary: #f0f0f0;
    --color-grey-secondary: #bbbbbb;
    --color-yellow-primary: goldenrod;
    --color-blue-primary: royalblue;
    --color-blue-secondary: dodgerblue;
    --color-red-primary: red;
    --color-orange-primary: darkorange;
    --color-green-primary: mediumseagreen;
  }
  
  * {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  html {
    font-size: 62.5%;
    font-family: "Comic Neue", cursive;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .productLayout {
    display: flex;
  }
  
  .primary__button {
    padding: 1.5rem 2rem;
    font-size: 1.6rem;
    border: none;
    color: white;
    background-image: linear-gradient(
      135deg,
      black 0%,
      black 45%,
      var(--color-yellow-primary) 45%
    );
    background-size: 230%;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .primary__button:hover {
    background-position: 100%;
    color: black;
  }
  
  .secondary__button {
    padding: 1.5rem 2rem;
    font-size: 1.6rem;
    border: 0.2rem solid var(--color-yellow-primary);
    color: var(--color-yellow-primary);
    background-color: white;
    text-transform: uppercase;
    cursor: pointer;
  }
  