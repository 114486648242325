.footList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5px;
    padding-top: 1rem;
    background-color: #000;
    
}
    
.footCard {
    position: relative;
    margin: 1rem;
    padding-top: 1rem;
    flex: 1 0 25rem;
    max-width: 35rem;
    max-height: auto;
    width: 30rem;
    transition: 0.2s;
    color: #fff;
}
.footView {
    padding-left: 35%;
}
.footRow {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
}
.footTitle {
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 1rem;
}
.fooText {
    font-size: 1.4rem;
    color: #999999;
}
.footSubTitle {
    font-size: 1.4rem;
    font-weight: 600;
    padding-top: 0.3rem;
}
.footIcon {
    font-size: 2.4rem;
    color: #fff;
    padding-right: 1rem;
}
.footLogo1 {
    height: 100px;
    display: block;
    margin: auto;
    margin-bottom: 0.5rem;
}
.footLogo {
    height: 85px;
    display: block;
    margin: auto;
    margin-bottom: 0.5rem;
}
.footStyle {
    font-style: italic;
    padding-bottom: 0.2rem;
}
.footRights {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding-bottom: 1rem;
    font-size: 1rem;
}
.footPrivacy {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding-bottom: 1rem;
    font-size: 1rem;
    cursor: pointer;
}
.foot_row_images {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.foot_ImageStore{
    height: 3.5rem;
    margin: 0 0.5rem;
    cursor: pointer;
}