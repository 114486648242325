.export_App{
    justify-content: center;
}.export_contain{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 18rem;
    justify-content: center;
    align-items: center;
}
.export_title{
    font-size: 1.8rem;
    padding: 1rem 0;
    font-weight: bold;
}
.export_subTitle{
    font-size: 1rem;
    font-weight: 700;
}
.export_text{
    font-size: 1rem;
    font-weight: 700;
}
.export_thanks{
    font-size: 1.2rem;
    font-weight: bold;
}
.export_Top {
    padding-top: 3rem;
}

.export_row {
    display: flex;
    justify-content: center;
}
.export_column_header_cant {
    font-size: 8px;
    font-weight :bold;
    width: 3rem;
    text-align: center;
}
.export_column_header_desc{
    font-size: 8px;
    font-weight :bold;
    width: 6rem;
    text-align: left;
}
.export_column_header {
    font-size: 8px;
    font-weight :bold;
    width: 4.5rem;
    align-items: center;
}
.export_column_item_cant {
    font-size: 8px;
    font-weight :800;
    width: 3rem;
    text-align: center;
    flex-wrap: wrap;
}
.export_column_items_desc {
    font-size: 8px;
    font-weight :800;
    width: 6rem;
    text-align: left;
    flex-wrap: wrap;
}
.export_column_items {
    font-size: 8px;
    font-weight :800;
    width: 4.5rem;
    text-align: center;
    flex-wrap: wrap;
}
.export_column_total_cant {
    font-size: 8px;
    font-weight :bold;
    width: 3rem;
    text-align: center;
    flex-wrap: wrap;
    border-top: 0.5px solid black;
}
.export_column_total_desc {
    font-size: 8px;
    font-weight :bold;
    width: 6rem;
    text-align: left;
    flex-wrap: wrap;
    border-top: 0.5px solid black;
}
.export_column_total {
    font-size: 8px;
    font-weight :bold;
    width: 4.5rem;
    text-align: center;
    flex-wrap: wrap;
    border-top: 0.5px solid black;
}

.export_column_subTotal_desc {
    font-size: 8px;
    font-weight :800;
    width: 9rem;
    text-align: left;
    flex-wrap: wrap;
}
.export_column_subTotal {
    font-size: 8px;
    font-weight :800;
    width: 4.5rem;
    text-align: center;
    flex-wrap: wrap;
}