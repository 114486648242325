.App {
  text-align: center;
  background-color: #f7f7f7;
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}


.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.loader {
  margin: auto;
  margin-top: 2rem;
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #00C1FF;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.App-link {
  color: #61dafb;
}

.scrollHorizontal {
  overflow: auto;
  white-space: nowrap;
}

.style_full_height {
  height: 80vh;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 2rem 1rem 2rem;
  background-color: #FAFAFA;
  height: 99vh;
}

.signinCard {
  position: relative;
  background-color: #fff;
  margin: 1rem;
  padding: 5rem 10rem 10rem 10rem;
  flex: 1;
  width: 90%;
  max-height: auto;
  transition: 0.2s;
}

.signupCard {
  position: relative;
  background-color: #ffffff;
  margin: 1rem;
  padding: 1rem 0 1rem 0;
  flex: 1;
  width: 90%;
  max-height: auto;
  transition: 0.2s;
}

.signupCard:hover,
.signinCard:hover {
  transform: translateY(0rem);
  box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}

.signinImage {
  height: 100px;
  margin: 1rem 0rem 2rem 0rem;
}

.label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}

.error_label {
  font-size: 1.2rem;
  color: red;
  margin-top: 2.5rem;
}

.input {
  background-color: #E3E3E3;
  border-radius: 1.2rem;
  padding: 1rem;
  padding-left: 10px;
  font-weight: 300;
  width: 30rem;
  transition: .2s;
  margin-top: 30px;
}

.error_input {
  font-size: 1rem;
  color: red;
}

.inputSignup {
  background-color: #E3E3E3;
  border-radius: 1.5rem;
  padding: 1rem;
  padding-left: 10px;
  font-weight: 300;
  width: 30rem;
  transition: .2s;
  margin-top: 15px;
}

.button {
  margin-top: 30px;
  width: 15rem;
  background-color: #00C1FF;
  color: #ffffff;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  border-radius: 1rem;
  cursor: pointer;
}

.displayStack_login {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textRegister1 {
  font-size: 14px;
  margin-right: 2rem;
}

.textRegister2 {
  font-size: 14px;
  font-weight: bold;
  text-decoration-line: underline;
  color: darkblue;
  cursor: pointer;
}

/*Congif CSS*/
.configList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 2rem 1rem 2rem;
  height: 70vh;
}

.configCard {
  position: relative;

  margin: 1rem;
  padding: 2rem 0 2rem 0;
  flex: 1;
  max-width: 40rem;
  max-height: auto;
  transition: 0.2s;
}

.configBox {
  flex: 1;
  background-color: #ffffff;
  margin: 5px;
  padding: 2rem 0 2rem 0;
  border: 1px solid #D1D1D4;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.configBox:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}

.config_icon {
  font-size: 2rem;
  transition: 0.2s;
  margin-right: 5px;
}

.config_text {
  font-size: 16px;
}

.not_found {
  font-size: 4.2rem;
}

.FAQ_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 2rem 1rem 2rem;
  background-color: #FAFAFA;
}

.FAQ_Card {
  position: relative;
  background-color: #fff;
  margin: 1rem;
  padding: 4rem 1rem;
  width: 90%;
  max-height: auto;
  transition: 0.2s;
}

.FAQ_title {
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.FAQ_question {
  font-size: 1.4rem;
  font-weight: 600;
}

.FAQ_answer {
  font-size: 1.4rem;
  padding-bottom: 1.5rem;
}

.Privacy_title {
  font-size: 4rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.Privacy_subTitle {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.5rem;
}

.Privacy_text {
  font-size: 1.4rem;
  padding-bottom: 1.5rem;
  text-align: left;
  margin-bottom: 1rem;
}

@media screen and (max-width: 825px) {
  .signinCard {
    width: 15rem;
    height: 75vh;
    padding: 5rem 1rem 5rem 1rem;
  }
  .input,
  .inputSignup {
    background-color: #F3F3F3;
    width:25rem
  }
}