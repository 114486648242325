.add__cart--toast {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10rem;
    bottom: 5rem;
    font-size: 1.75rem;
    width: fit-content;
    height: 3.5rem;
    border-radius: 1rem;
    background-color: var(--color-yellow-primary);
    z-index: 20;
    color: #fff;
  }
  
  .add__cart--toast > * {
    margin: 0 1rem;
  }
  
  @media screen and (max-width: 825px) {
    .add__cart--toast {
      right: 0rem;
      font-size: 1.5rem;
      padding: 1rem 0;
    }
    
  }