.navContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 10px;
    z-index: 10;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid var(--color-grey-primary);
    background-color: #fff;
  }
  
  .navBar {
    display: flex;
    align-items: center;
    flex: 1;
  }
  
  .navBar__logo {
    height: 3.4rem;
    margin-left: 1rem;
  }
  .navBar__log2 {
    height: 45px;
    margin: auto;
  }

  .headerName{
    flex: 0.5;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    text-align: left;
    padding-left: 1rem;
  }
  
  .searchBar {
    flex: 2;
    display: flex;
    justify-content: center;
    transition: 0.3s;
  }
  .searchBar_Order {
    flex: 3;
    display: flex;
    justify-content: left;
    transition: 0.3s;
  }

  .searchBar__input--wrapper {
    position: relative;
  }
  
  .searchBar__input {
    outline: none;
    border: 0.5px solid darkgray;
    border-radius: 0.5rem;
    height: 3.5rem;
    width: 50rem;
    font-size: 1.4rem;
    padding: 1rem;
  }
  
  .searchBar__placeholder {
    color: darkgray;
    position: absolute;
    left: 0;
    top: 0.9rem;
    font-size: 1.4rem;
    padding-left: 10px;
    transition: all 0.2s;
  }
  
  .searchBar__input:focus ~ .searchBar__placeholder,
  .searchBar__input:invalid ~ .searchBar__placeholder {
    transform: translateY(-2.5rem);
    opacity: 0;
  }
  
  .searchBar__line {
    position: absolute;
    bottom: 0;
    height: 0.2rem;
    width: 100%;
  }
  
 
  
  .searchBar__input:focus ~ .searchBar__line::before,
  .searchBar__input:invalid ~ .searchBar__line::before {
    transform: scaleX(1);
    transform-origin: 50%;
  }
  
  .navBar__icons {
    flex: 1;
    display: flex;
    justify-content:right;
    align-items: center;
  }
  
  .navBar__icons > * {
    padding: 0 2rem;
  }
  .navBar__icons--search {
    margin-top: 0rem;
  }

  .navBar__icons--user{
    font-size: 1.6rem;
    cursor: pointer;
    transition: 0.2s;
    color: gray;
    margin-right: 0.3rem;
  }
  
  .navBar__icons--home,
  .navBar__icons--menu,
  .navBar__icons--search,
  .navBar__icons--cart,
  .navBar__icons--wishlist {
    font-size: 2.8rem;
    cursor: pointer;
    transition: 0.2s;
    color: #000;
  }
  .navBar__icons--home {
    font-size: 2.4rem;
  }
  
  .navBar__icons--home:hover,
  .navBar__icons--menu:hover,
  .navBar__icons--search:hover,
  .navBar__icons--cart:hover,
  .navBar__icons--wishlist:hover {
    fill: #ed1c24;
    
  }
  
  .cart__wrapper,
  .filter__wrapper,
  .wishlist__wrapper {
    position: relative;
  }
  
  .navBar__icons--home {
   margin-left: 2rem; 
  }
  .navBar__icons--menu {
    margin-top: 0rem;
  }
  
  .navBar__icons--cart,
  .navBar__icons--wishlist {
    height: 3rem;
  }
  
  .navBar__icons--wishlist {
    font-size: 2.25rem;
  }
  
  .navBar__icons--cart {
    margin-top: -0rem;
  }
  
  .badge__number {
    position: absolute;
    top: -0.5rem;
    right: 0.75rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #ed1c24;
    color: white;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .badge__number_order {
    position: absolute;
    top: -0.5rem;
    right: 0.75rem;
    width: 2.5rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--color-blue-secondary);
    color: white;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .displayRowSignin{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .headerSignin{
    font-size: 12px;
    color: #000;
    padding-left: 5px;
  }
  .nav_account_right{
    font-size: 1.4rem;
    font-weight: 400;
    padding-right: 0.5rem;
    color: gray;
  }

  .nav_add_right {
    margin-right: 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #000;
    cursor: pointer;
  }

  .nav_subNav{
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 2rem;
    background-color: #FFF;
  }
  .nav_row_flex {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .nav_row {
    display: flex;
    align-items: center;
  }
  .nav_sub_icon{
    font-size: 1.6rem;
    color: gray;
    padding-left: 1rem;
  }
  .nav_sub_title{
    font-size: 1.4rem;
    font-weight: 300;
    padding-left: 1rem;
    cursor: pointer;
    text-transform: lowercase;
  }

  .nav_sub_title:hover{
    color: #ed1c24;
  }
  
  @media screen and (max-width: 825px) {
    .navBar__logo {
      height:3.2rem;
    }

    .headerName{
      font-size: 14px;
      font-weight: 400;
      padding-left: 0.2rem;
    }
  
    .navBar__icons > * {
      padding: 0 0.8rem;
    }
    .navBar__icons--home,
    .navBar__icons--menu,
    .navBar__icons--search,
    .navBar__icons--cart {
      font-size: 2rem;
    }

    .navBar__icons--home {
      margin-left: 1rem; 
     }
  
    .navBar__icons--cart {
      margin-top: 0rem;
    }
  
    .navBar__icons--wishlist {
      font-size: 1.5rem;
    }
  
    .navBar__icons--menu,
    .navBar__icons--search {
      margin-top: 0rem;
    }
    .navBar__icons--user{
      font-size: 1.4rem;
      margin-bottom: 3px;
    }
  
    .badge__number {
      top: 0;
      right: 1rem;
      font-size: 1rem;
      height: 1.5rem;
      width: 1.5rem;
    }

    .badge__number_order {
      top: 0;
      right: 1rem;
      font-size: 1rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  
    .searchBar__input {
      width: 28rem;
    }
  
    .navBar__icons {
      margin-right: 0rem;
    }
  }
  