.order_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 4rem 8rem 4rem;
    }
.order_top_box_btn {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 1rem 2rem;
}
.order_filter{
    background-color: #fff;
    flex: 1;
    margin-left: 1rem;
    max-width: 35rem;
    max-height: auto;
    transition: 0.2s;
}
.order_filter_box {
    text-align: left;
    padding: 1rem;
    margin: 2rem 1rem;
}
.order_filter_label {
    font-size: 1.6rem;
    font-weight: 400;
    cursor: pointer;

}
.order_filter_selected {
    font-size: 1.6rem;
    font-weight: 400;

    cursor: pointer;
    border-bottom: solid 2px orange;
}
.order_filter_selected:hover,
.order_filter_label:hover {
    border-bottom: solid 2px var(--color-yellow-primary);
}

.order_card {
    background-color: #fff;
    flex: 1;
    max-width: 80%;
    max-height: auto;
    transition: 0.2s;
    margin-left: 2rem;
}
.order_empty_box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
}
.order_empty_text {
text-align: center;
font-size: 1.6rem;
font-weight: 100;
}
.order_box {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem;
    border-bottom: 0.1rem solid silver;
    cursor: pointer;
}
.order_box:hover {
transform: translateY(-0.3rem);
box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}
.order_box_image {
width: 15rem;
margin-right: 2rem;
}
.order_image {
width: 15rem;
height: auto;
}
.order_box_par {
width: 5rem;
justify-content: center;
}
.order_box_info {
width: 25rem;
margin-left: 5rem;
justify-content: left;
text-align: left;
}
.order_box_text{
font-size: 1.4rem;
font-weight: 100;
}
.order_box_text_right{
    font-size: 1.2rem;
    font-weight: 400;
    color: navy;
    margin-left: 3rem;
    text-decoration-line: underline;
    cursor: pointer;
}
.order_box_bold{
font-weight: 700;
}
.order_box_row {
    display: flex;
    align-items: center;
}
.order_margin_button {
    margin-bottom: 1.2rem;
}
.order_box_space {
    justify-content: space-between;
}
.order_box_center {
    justify-content: center;
}

.order_date {
    font-size: 1.8rem;
    padding: 0.5rem 2rem;
    font-weight: 500;
    cursor: pointer;
}
.order_icon_Tab {
    font-size: 24px;
    margin-right: 1rem;
    color: darkgray;
    cursor: pointer;
}

.order_icon {
    font-size: 24px;
    cursor: pointer;
}
.order_orange {
color: orange;
}
.order_red {
color: red;
}
.order_green {
color: green;
}
.order_bottonWidth {
    border-bottom: solid 1px darkgray;
    margin-bottom: 1rem;
}
.order_cursor {
    cursor: pointer;
}

.order_button {
    width: 15rem;
    padding: 1rem 0;
    margin: 0 1rem;
    border-bottom: solid 0.5px darkgray;
    border-radius: 10px;
    align-items: center;
    cursor: pointer;
}
.order_button_text {
    text-align: center;
    font-size: 14px;
    color: #fff;
}
.order_bg_blue {
    background-color: #00C1FF;
}
.order_bg_red {
    background-color: red;
}
.order_bg_orange {
    background-color: orange;
}
.order_bg_green {
    background-color: green;
}

.order_spei{
    background-color: #fff;
    flex: 1;
    margin-left: 1rem;
    max-width: 35rem;
    max-height: auto;
    transition: 0.2s;
    border-style: solid;
    border-width: 0.3px;
    border-color: silver;
    border-radius: 3%;
}
.order_text_spei {
    white-space: pre-line;
    text-align: left;
    margin-left: 2rem;
    font-size: 12px;
    margin-bottom: 1rem;
}
.order_text_spei_more{
    text-align: left;
    font-size: 10px;
    margin-left: 2rem;
    margin-top: 0rem;
    font-weight:600;
}

@media screen and (max-width: 825px) {
    .order_list{
        margin: 1rem;
    }
    .order_card {
        width: 35rem;
        max-width: 35rem;
        margin-top: 2rem;
        margin-left: 0rem;
    }
    .order_box {
        margin: 0;
        width: 33rem;
    }
    .order_box_image {
        width: 5rem;
        margin-right: 1rem;
    }
    .order_image {
        width: 5rem;
    }
    .order_box_par {
        width: 3rem;
    }
    .order_box_info {
        width: 20rem;
        margin-left: 2rem;
    }
}